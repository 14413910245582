export default () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const isDevEnvironment =
    location.hostname === "localhost" || /\.local$/i.test(location.hostname);

  let isSentryLoaded = false;

  const onFirstError = async (event: ErrorEvent) => {
    const Sentry = await import("@sentry/browser");

    !isSentryLoaded &&
      Sentry.init({
        dsn: "https://eabf915d036565d62af676d092ed675b@o4508256406208512.ingest.de.sentry.io/4508336710680656",
        enabled: !isDevEnvironment,
      });

    isSentryLoaded = true;
    window.removeEventListener("error", onFirstError);

    Sentry.captureException(event.error);
  };

  window.addEventListener("error", onFirstError);
};
